import { TypeAnimation } from 'react-type-animation';
function TypeAnimationSri() {

    return <TypeAnimation
            sequence={[
            'Software Engineer',
            1000,
            'Traveler',
            1000,
            'Gym freak',
            1000,
            'Runner',
            1000,
            ]}
            wrapper="span"
            speed={50}
            className="name-animation"
            repeat={Infinity}/>  

}

export default TypeAnimationSri;