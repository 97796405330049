import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import WorkIcon from '@mui/icons-material/Work';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import CodeIcon from '@mui/icons-material/Code';
import Typography from '@mui/material/Typography';

export default function CareerTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          NOV 2021 - FEB 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <img src ="/cme.png" alt="CME Icon" style={{ width: '50px', height: '50px' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            CME Pune
          </Typography>
          <Typography>Software Engineer Research Intern</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          MAR 2022 - MAY 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />

          <img src ="/bnym.png" alt="BNYM Icon" style={{ width: '60px', height: '60px' }} />


          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            BNY Mellon
          </Typography>
          <Typography>Software Engineer Intern</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          FEB 2023 - MAY 2023
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />

          <img src ="/botminds.png" alt="Botminds Icon" style={{ width: '40px', height: '40px' }} />


          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Botminds.ai
          </Typography>
          <Typography>AI Intern</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          AUG 2023 - Present
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          
          <img src ="/cisco.png" alt="Cisco Icon" style={{ width: '50px', height: '50px' }} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Cisco
          </Typography>
          <Typography>Software Engineer</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
