import './App.css';
import React from 'react';
import TypeAnimationSri from './components/TypeAnimationSri/TypeAnimationSri';
import CustomizedTimeline from './components/CustomizedTimeline/CustomizedTimeline';

function App() {

  return (
    <div>
      {/* bootstrap */}
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"/>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>

      <div className="container_heading">
        <h1 id='heading'>Sri Vaishnav</h1>
        <TypeAnimationSri/>
      </div>
      <div className='container_main'>
      <h1 id='career_heading'>Career</h1>
        <CustomizedTimeline/>
      </div>
      
    </div>
  );
}

export default App;